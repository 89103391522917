import { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { PermifyProvider } from '@permify/react-role';
import LoginPage from './pages/LoginPage';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

// ----------------------------------------------------------------------

export default function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(localStorage.getItem('role'));
  }, []);

  return (
    // <PermifyProvider>
    //   {user === null ? (
    //     <Login />
    //   ) : (
    //     <HelmetProvider>
    //       <BrowserRouter>
    //         <ThemeProvider>
    //           <ScrollToTop />
    //           <StyledChart />
    //           <Router />
    //         </ThemeProvider>
    //       </BrowserRouter>
    //     </HelmetProvider>
    //   )}
    // </PermifyProvider>
    <PermifyProvider>
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router />
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </PermifyProvider>
  );
}
