import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { usePermify } from '@permify/react-role';
import { styled } from '@mui/material/styles';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton, Alert, AlertTitle } from '@mui/lab';
// components
import axios from 'axios';
import Iconify from '../components/iconify';
import PettieApi from '../config.json';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 405,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: '#051C49',
}));

const loginLogo = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '50%',
  display: 'flex',
  justifyContent: 'center',
}));

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function LoginForm() {
  const navigate = useNavigate();
  const { setUser } = usePermify();
  const [showPassword, setShowPassword] = useState(false);
  const [usernameValue, setUserName] = useState(null);
  const [passwordValue, setPassword] = useState(null);

  const handleClick = async () => {
    const response = await axios
      .post(`${PettieApi.API}/login`, {
        username: usernameValue,
        password: passwordValue,
      })
      .then((response) => {
        localStorage.setItem('token', response?.data?.token);
        setUser({
          id: '2',
          roles: ['admin'],
          permissions: ['show'],
        });
        localStorage.setItem('role', response?.data?.user?.type);
        const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };

        axios.get(`${PettieApi.API}/users/${response?.data?.user?.id}`, { headers }).then((data) => {
          if (data) {
            localStorage.setItem('UserID', data?.data?.user?.vetsOfficeId);
            localStorage.setItem('UserName', [data?.data?.user?.firstName, data?.data?.user?.lastName].join(' '));
          }
        });

        navigate('/dashboard', { replace: true });
      })
      .catch((error) => {
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            This is an error alert — <strong>check it out!</strong>
          </Alert>
        </Stack>;
      });
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <>
      <StyledRoot>
        <StyledSection>
          {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
          Hi, Welcome Back
        </Typography> */}
          <loginLogo>
            <img className="loginLogo" src="/assets/loginLogo.png" alt="login" />
          </loginLogo>
        </StyledSection>
        <StyledContent>
          <Stack spacing={3}>
            <TextField
              name="email"
              label="Username"
              onChange={(event) => {
                setUserName(event.target.value);
              }}
            />

            <TextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            {/* <Checkbox name="remember" label="Remember me" /> */}
            {/* <Link variant="subtitle2" underline="hover">
              Forgot password?
            </Link> */}
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
            Login
          </LoadingButton>
        </StyledContent>
      </StyledRoot>
    </>
  );
}
