import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import axios from 'axios';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Box,
  Fade,
  Backdrop,
  Modal,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { HasAccess } from '@permify/react-role';
import PettieApi from '../config.json';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/pets';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'pet', label: 'Pet', alignRight: false },
  { id: 'vet', label: 'Veterinary', alignRight: false },
  { id: 'createdAt', label: 'Created Date', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },

  { id: '' },
];

// ----------------------------------------------------------------------

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {/* <CloseIcon /> */}
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function AppointmentPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [USERLIST, setUSERLIST] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [vetsOfficeIdValue, setVetsOfficeId] = useState(null);
  const [vetIdValue, setVetId] = useState(null);
  const [petIdValue, setPetId] = useState(null);
  const [descriptionValue, setDescription] = useState(null);
  const [ownerIdValue, setownerIdValue] = useState(null);
  const [dataID, setDataID] = useState(null);
  const [ModelData, setModelData] = useState(null);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleOpenCreate = (method) => {
    if (method === 'create') {
      setDataID(null);
    }
    setOpenCreate(true);
  };
  const handleCloseCreate = () => setOpenCreate(false);
  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    getDataList();
  }, []);
  useEffect(() => {
    setDataID(ModelData?._id);
  }, [ModelData]);

  const getDataList = () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };

    const response = axios;
    axios
      .get(
        userType === 'admin'
          ? `${PettieApi.API}/dashboard/appointments`
          : `${PettieApi.API}/vetsOffices/${localStorage.getItem('UserID')}/appointments`,
        { headers }
      )
      .then((response) => {
        setUSERLIST(response?.data?.appointments);
      });
  };

  const onSubmit = () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const newData = {
      vetsOfficeId: !vetsOfficeIdValue && dataID ? ModelData?.vetsOfficeId : vetsOfficeIdValue,
      vetId: !vetIdValue && dataID ? ModelData?.vetId : vetIdValue,
      petId: !petIdValue && dataID ? ModelData?.petId : petIdValue,
      description: !descriptionValue && dataID ? ModelData?.description : descriptionValue,
      ownerId: !ownerIdValue && dataID ? ModelData?.ownerId : ownerIdValue,
    };

    const Url = dataID ? `${PettieApi.API}/appointments/${dataID}` : `${PettieApi.API}/appointments`;
    const Method = dataID ? 'patch' : 'post';

    const response = axios;
    axios[Method](Url, { ...newData }, { headers }).then((response) => {
      setVetsOfficeId(null);
      setVetId(null);
      setPetId(null);
      setDescription(null);
      setownerIdValue(null);

      handleCloseMenu();
      handleCloseCreate();
      getDataList();
    });
  };

  const onDelete = (dataID) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };

    const response = axios;
    axios.delete(`${PettieApi.API}/appointments/${dataID}`, { headers }).then((response) => {
      handleCloseMenu();
      handleClose();
      getDataList();
    });
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Pad the day and month with leading zeros if necessary
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    // Return the formatted date string
    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  const userType = localStorage.getItem('role');
  return (
    <>
      <Helmet>
        <title> User | Pettie Admin Panel </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Appointment
          </Typography>
          {userType === 'admin' ? (
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleOpenCreate('create')}
            >
              New Appointment
            </Button>
          ) : (
            ''
          )}
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {userType === 'admin' ? (
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { pet, vet, id, description, createdAt, avatarUrl } = row;
                      const selectedUser = selected.indexOf(pet) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, pet)} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={pet} src={avatarUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {pet}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{vet}</TableCell>
                          <TableCell align="left">{formatDate(createdAt)}</TableCell>

                          <TableCell align="left">{description}</TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                              <Iconify icon={'eva:more-vertical-fill'} onClick={() => setModelData(row)} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              ) : (
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { petId, vetId, id, description, createdAt, avatarUrl } = row;
                      const selectedUser = selected.indexOf(petId) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, petId)} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={petId} src={avatarUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {petId}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{vetId}</TableCell>
                          <TableCell align="left">{formatDate(createdAt)}</TableCell>

                          <TableCell align="left">{description}</TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleOpenCreate('edit')}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={handleClickOpen}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <Dialog
        open={openCreate}
        onClose={handleCloseCreate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dataID ? 'Edit Appointment' : 'Create New Appointment'}</DialogTitle>
        <DialogContent>
          <Stack spacing={3} pr={15} pl={15}>
            <TextField
              id="standard-textarea"
              label="Vets Office ID"
              placeholder="Placeholder"
              multiline
              variant="standard"
              defaultValue={dataID ? ModelData?.vetsOfficeId : null}
              onChange={(event) => {
                setVetsOfficeId(event.target.value);
              }}
            />
            <TextField
              id="standard-textarea"
              label="Vet ID"
              placeholder="Placeholder"
              multiline
              variant="standard"
              defaultValue={dataID ? ModelData?.vetId : null}
              onChange={(event) => {
                setVetId(event.target.value);
              }}
            />
            <TextField
              id="standard-textarea"
              label="Owner ID"
              placeholder="Placeholder"
              multiline
              variant="standard"
              defaultValue={dataID ? ModelData?.ownerId : null}
              onChange={(event) => {
                setownerIdValue(event.target.value);
              }}
            />
            <TextField
              id="standard-textarea"
              label="Pet ID"
              placeholder="Placeholder"
              multiline
              variant="standard"
              defaultValue={dataID ? ModelData?.petId : null}
              onChange={(event) => {
                setPetId(event.target.value);
              }}
            />
            <TextField
              id="standard-textarea"
              label="Description"
              placeholder="Placeholder"
              multiline
              variant="standard"
              defaultValue={dataID ? ModelData?.description : null}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreate}>Cancel</Button>
          <Button onClick={onSubmit} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              ATTENTION
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete this appointment?
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
              <Button onClick={handleClose}>Back</Button>
              <Button onClick={() => onDelete(dataID)} autoFocus>
                Delete
              </Button>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
