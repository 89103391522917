// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const userType = localStorage.getItem('role');

const vetNav = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'pets',
    path: '/dashboard/pets',
    icon: icon('pets'),
  },

  {
    title: 'Vets',
    path: '/dashboard/vets',
    icon: icon('veterinary-care'),
  },
  {
    title: 'Appointment',
    path: '/dashboard/appointment',
    icon: icon('appointments-solid'),
  },
];

const adminNav = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'pets',
    path: '/dashboard/pets',
    icon: icon('pets'),
  },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: icon('user'),
  },
  {
    title: 'Vets Office',
    path: '/dashboard/vets-office',
    icon: icon('veterinary-care'),
  },
  {
    title: 'Appointment',
    path: '/dashboard/appointment',
    icon: icon('appointments-solid'),
  },
];

const navConfig = userType === 'admin' ? adminNav : vetNav;

export default navConfig;
