import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import PetsPage from './pages/PetsPage';
import UserPage from './pages/UserPage';
import VetsOfficePage from './pages/VetsOfficePage';
import VetsPage from './pages/VetsPage';
import AppointmentPage from './pages/AppointmentPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';

// ----------------------------------------------------------------------

export default function Router() {
  const token = localStorage.getItem('token');

  const routes = useRoutes([
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'pets', element: <PetsPage /> },
        { path: 'users', element: <UserPage /> },
        { path: 'vets-office', element: <VetsOfficePage /> },
        { path: 'vets', element: <VetsPage /> },
        { path: 'appointment', element: <AppointmentPage /> },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to={token ? '/dashboard/app' : '/login'} />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
